import { CloseIcon, EmailIcon, LockIcon, PhoneIcon, SearchIcon, TvIcon } from 'assets/icons';
import { LogoIconZiggo, LogoZiggo } from 'assets/img/Logo';
import { AISearch } from 'components/BaseLayout/components/AISearch';
import { Account } from 'components/BaseLayout/components/Account';
import Navigation from 'components/BaseLayout/components/Navigation';
import { appSettings } from 'config/app.settings';
import React, { useState } from 'react';
import Media from 'react-media';
import { NavLink } from 'react-router-dom';
import { mobileEnd, mobilePlusEnd, mobilePlusStart, tabletPortraitStart } from 'theme';
import { ContentUtil } from 'utils/content';
import { BrowserEnum, deviceUtils } from 'utils/device';

import { content } from './header.content';
import * as S from './header.styles';

const contentUtil = new ContentUtil({ content, componentName: 'Header' });

interface IHeaderProps {
  isAuthenticated: boolean;
}

export const Header = ({ isAuthenticated }: IHeaderProps) => {
  const [showAISearchBar, setShowAISearchBar] = useState(false);

  const openAISearchBar = () => {
    setShowAISearchBar(true);
  };

  const closeAISearchBar = () => {
    setShowAISearchBar(false);
  };

  return (
    <S.Header data-testid="header">
      {!deviceUtils.isBrowser(BrowserEnum.app) && (
        <S.Wrapper>
          <S.LinksLeft>
            {isAuthenticated ? (
              <>
                <Media query={`(max-width:${mobilePlusEnd}px)`}>
                  <S.Account>{isAuthenticated && <Account />}</S.Account>
                </Media>
                <S.HomeLink href={`${appSettings.ENV_ZIGGO_URL}/`}>
                  <S.Chevron />
                  {contentUtil.translate('home')}
                </S.HomeLink>
              </>
            ) : (
              <S.UnAuthHomeLink href={`${appSettings.ENV_ZIGGO_URL}/`}>
                <LogoIconZiggo width="60" height="28" />
                <S.ScreenReaderOnly>{contentUtil.translateToString('home')}</S.ScreenReaderOnly>
              </S.UnAuthHomeLink>
            )}
            <Media query={`(max-width:${mobileEnd}px)`}>
              <S.DefaultLink href="https://www.ziggogo.tv/" target="_blank" data-testid="header-ziggo-go">
                <TvIcon height={20} width={20} />
                {contentUtil.translate('ziggoGo')}
              </S.DefaultLink>
            </Media>
          </S.LinksLeft>

          {isAuthenticated ? (
            <NavLink to="/">
              <Media query={`(max-width:${mobilePlusEnd}px)`}>
                <LogoIconZiggo width="30" height="28" />
              </Media>
              <Media query={`(min-width:${tabletPortraitStart}px)`}>
                {/* Fragment prevents Media from wrapping and spreading its props to the LogoZiggo svg */}
                <>
                  <LogoZiggo width="130" height="28" />
                </>
              </Media>
              <S.ScreenReaderOnly>{contentUtil.translateToString('overview')}</S.ScreenReaderOnly>
            </NavLink>
          ) : (
            <Media query={`(min-width:${mobileEnd}px)`}>
              <S.LockLink href="/">
                <LockIcon />
                {contentUtil.translate('secureConnection')}
              </S.LockLink>
            </Media>
          )}

          {isAuthenticated ? (
            <S.LinksRight>
              <Media query={`(min-width:${mobilePlusStart}px)`}>
                <S.DefaultLink href="https://www.ziggogo.tv/" target="_blank" data-testid="header-ziggo-go">
                  <TvIcon height={20} width={20} />
                  {contentUtil.translate('ziggoGo')}
                </S.DefaultLink>
              </Media>
              {showAISearchBar ? (
                <S.ToggleSearchMobile data-testid="ai-search" onClick={closeAISearchBar}>
                  <CloseIcon data-testid="close-ai-search" height="20" />
                  {contentUtil.translate('close')}
                </S.ToggleSearchMobile>
              ) : (
                <S.ToggleSearchMobile data-testid="ai-search" onClick={openAISearchBar}>
                  <SearchIcon data-testid="open-ai-search" height="20" />
                  {contentUtil.translate('search')}
                </S.ToggleSearchMobile>
              )}
              <S.DefaultLink href="https://mail.ziggo.nl/" data-testid="header-mail">
                <EmailIcon height="20" />
                {contentUtil.translate('ziggoMail')}
              </S.DefaultLink>
              <Media query={`(min-width:${tabletPortraitStart}px)`}>
                <S.Account>{isAuthenticated && <Account />}</S.Account>
              </Media>
            </S.LinksRight>
          ) : (
            <S.LinksRight>
              <S.PhoneLink href={`tel: ${content.nl.phone}`}>
                <PhoneIcon fill="#f48c00" />
                {contentUtil.translate('phone')}
              </S.PhoneLink>
            </S.LinksRight>
          )}
        </S.Wrapper>
      )}
      {showAISearchBar && (
        <S.SearchMobile>
          <AISearch toggleSearchBar={closeAISearchBar} />
        </S.SearchMobile>
      )}
      {isAuthenticated && (
        <Media query={`(min-width:${tabletPortraitStart}px)`}>
          <Navigation />
        </Media>
      )}
    </S.Header>
  );
};
